<template>
    <div class="container">
        <div class="row">
            <div class="col-12 py-4">
                <div class="p-fluid container-fluid">
                    <Button
                        class="p-button-raised p-button-rounded my-3 p-button-danger" 
                        icon="pi pi-arrow-left"
                        @click="goBack" 
                    />
                    <h3> <strong>#Username </strong> Access</h3>

                    <div class="col">
                        <div class="my-3">
                            <label for="floatingInput">Email address</label>
                            <InputText 
                                type="text" 
                                placeholder="name@example.com" 
                                autocomplete="off"
                                v-model="userForm.email" 
                            />
                        </div>
                    </div>

                    <div class="col">
                        <div class="my-3">
                            <label for="floatingInput">Password</label>
                            <InputText 
                                type="password" 
                                class="form-control" 
                                autocomplete="off"
                                v-model="changePassword" 
                                placeholder="************"
                            />
                        </div>
                    </div>

                    <div class="col">
                        <div class="my-3">
                            <label for="floatingInput">First Name</label>
                            <InputText 
                                type="text" 
                                class="form-control" 
                                autocomplete="off"
                                v-model="userForm.firstName" 
                            />
                        </div>
                    </div>

                    <div class="col">
                        <div class="my-3">
                            <label for="floatingInput">Last Name</label>
                            <InputText 
                                type="text" 
                                class="form-control" 
                                autocomplete="off"
                                v-model="userForm.lastName" 
                            />
                        </div>
                    </div>

                    <div class="col">
                        <div class="my-3">
                            <label for="floatingInput">Roles</label>
                            <Dropdown 
                                v-model="userForm.roles"
                                :options="userRoles"
                                optionLabel="name"
                                optionValue="id"
                                class="p-button-outlined me-4"
                                style="width: 70%"
                                placeholder="Select Roles"
                            />
                        </div>
                    </div>

                    <div class="col">
                        <div class="my-3">
                            <Button
                                icon="pi pi-plus"
                                label="TOPICS"
                                class="p-button-success"
                                @click="addTopic"
                            />
                            <template v-for="topic, index in authorizationTopics" :key="topic.topicId">
                                <div class="d-flex my-4 ms-3 ">
                                    <Button 
                                        icon="pi pi-minus"
                                        class="p-button-danger p-button-rounded me-3"
                                        @click=" removeTopic(topic.topicId, index )"
                                    />
                                    <Dropdown 
                                        v-model="topic.topicId"
                                        :options="topics"
                                        option-label="name"
                                        option-value="topicId"
                                        placeholder="Select Topic"
                                        style="width: 95%"
                                    />
                                    <Calendar 
                                        v-model=" topic.date"
                                        dateFormat="dd/mm/yy" 
                                        placeholder="End Date"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-4">
                <div class="d-flex justify-content-end px-3">
                <Button
                    label="Save"
                    icon="pi pi-check"
                    class="p-button-success ml-3"
                    @click="authorizeTopic()"
                />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';

export default {
  name: 'AccessControlUserDetail',
  components:{
    Button,
    InputText,
    Dropdown,
    Calendar,
  },
  data() {
    return {
        loading: false, 
        authorizeLoading: false,
        authorizationTopics: [],
        userForm: {
            userName:null,
            email: null,
            password: null,
            roles:null,
            firstName: null,
            lastName: null,
            dateCreated: null,
            dateUpdated: null,
        },
        changePassword: null, 
    }
  },
  async created() {
    await this.getUserById();
    await this.getTopics();
    await this.getUserRoles();
    this.setUserForm();
  },
  computed:{
    selectedElement(){
        return this.$route.params.userId;
    },
    user(){
        return this.$store.getters.obtainUserData;
    },
    topics(){
        //Sort Alphabetically
        let topicSort = this.$store.getters.obtainTopics
        topicSort.sort(function (a,b){
            if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
        }); 
        return topicSort;
    },
    userRoles(){
        return this.$store.getters.obtionUserRoles
    }
  },
  methods:{
    goBack(){
        this.$router.go(-1)
    },
    // Topic Assign Functions
    removeTopic(topicId){
        this.authorizationTopics = this.authorizationTopics.filter(
            t => t.topicId != topicId
        );
    },
    addTopic(){
        let currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + 4);
        this.authorizationTopics.push({
            topicId:'',
            date: currentDate,
        });
    },
    // get User Details, topics , Roles and setTo VueForm  
    async getUserById(){
        try {
            this.loading= true;
            await this.$store.dispatch('obtainUser', this.selectedElement)
            this.loading = false;
        } catch (error) {
            this.loading= false
        }
    },
    async getTopics(){
        try {
            this.loading= true; 
            await  this.$store.dispatch('obtainTopics');
            this.loading= false;
        } catch (error) {
            this.loading= false; 
        }
    },
    async getUserRoles(){
        try{
            this.loading = true; 
            await this.$store.dispatch('obtainUserRoles');
            this.loading = false 
        } catch (error) {
            this.loading= false 
        }
    },
    setUserForm( ){
        this.userForm.email= this.user.email;
        this.userForm.firstName = this.user.firstName;
        this.userForm.lastName = this.user.lastName;
        this.userForm.userName= this.user.userName;
        this.userForm.password= this.user.passwordHash;
        if(this.user.userRoles.length > 0){
            this.userForm.roles = this.user.userRoles[0].roleId 
        }
    },
    // save User Detail, Topics, Roles
    async authorizeTopic(){
        try {
            this.authorizeLoading= true;
            let userTopic = []
            if(this.authorizationTopics.length > 0){
                this.authorizationTopics.map(topic =>
                    {
                        if(topic.topicId){
                            const date = new Date(topic.date).toISOString()
                            const topicDict = {
                                "topicId":topic.topicId , 
                                "dateLimit":date,
                                userTopicId: topic.userTopicId
                            }
                            userTopic.push(topicDict)
                        }
                    } 
                )
            }

            const roles = {
                roleId : this.userForm.roles 
            } 
            const userRoles = []
            userRoles.push(roles) 

            let userDetail = {};
            // for change user Password
            if(this.changePassword){ 
                // this.userForm.password = this.changePassword
                userDetail = {
                    email: this.userForm.email,
                    userName: this.userForm.userName,
                    firstName: this.userForm.firstName,
                    lastName: this.userForm.lastName, 
                    passwordHash: this.changePassword,
                    userRoles: userRoles,
                    userTopics: userTopic,
                }
            }
            else{
                userDetail = {
                    email: this.userForm.email,
                    userName: this.userForm.userName,
                    firstName: this.userForm.firstName,
                    lastName: this.userForm.lastName, 
                    // passwordHash: this.userForm.password,
                    userRoles: userRoles,
                    userTopics: userTopic,
                }
            }
            await this.$store.dispatch('modifyUserData', {user:userDetail, userId:this.selectedElement} );
            this.authorizeLoading= false;
            this.goBack(-1)
        } catch (error) {
            console.log(error);
            this.authorizeLoading= false; 
        }
    },
  },
  watch:{
    user(newVal) {
        if(newVal){
            let userTopic = [];
            if(newVal.userTopics){
                newVal.userTopics.map(topic => 
                    {
                        userTopic.push(
                            {
                                topicId: topic.topicId,
                                date: new Date(topic.dateLimit),
                                userTopicId: topic.userTopicId
                            }
                        )
                    }
                )
            }
            this.authorizationTopics= userTopic
        }
    },
    'authorizationTopics':{
        handler(newVal){
            try {
                if(newVal){
                    const newTopicId = newVal[newVal.length - 1].topicId;
                    const prevArray = newVal.slice(0, newVal.length -1 )
                    const topicExists = prevArray.findIndex(t=> t.topicId == newTopicId) >= 0; 
                    if(topicExists){
                        this.authorizationTopics.pop();
                    }
                }
            } catch (error) {
                null
            }
        },
        deep:true,
    },
  }
};
</script>